import { css } from '@emotion/react'
import { mq } from '@/components/media/media'
import color from '@/constants/color'

export const cssScalehackColor = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  font-weight: 700;
  color: ${color.black2};
  letter-spacing: 1px;
  background: ${color.orange4};
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssLink = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  color: ${color.white1};
  letter-spacing: 1px;
  background-image: linear-gradient(100deg, ${color.orange1}, ${color.orange2});
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssGradientButton = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  color: ${color.white1};
  letter-spacing: 1px;
  background-image: linear-gradient(100deg, ${color.blue1}, ${color.blue2});
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssShadowButton = css`
  box-shadow: 0 6px 8px ${color.black4};
`

export const cssNormalNavLink = css`
  padding: 12px 0;
  ${mq.pc} {
    padding: 0;
  }
  span span {
    font-size: 12px;
    font-weight: 600;
    color: ${color.black1};
    letter-spacing: 1.2px;
    &:last-of-type {
      font-size: 28px;
      font-weight: 500;
      color: ${color.black2};
      letter-spacing: 2.8px;
      ${mq.pc} {
        font-size: 16px;
      }
    }
  }
  div {
    margin-bottom: -24px;
    ${mq.pc} {
      margin-bottom: -18px;
      margin-left: 2px;
    }
  }
`

export const cssNewsLink = css`
  z-index: 99;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 1.4px;
  ${mq.pc} {
    font-size: 16px;
  }
  div {
    width: 14px;
  }
`

export const cssShadowGradientButton = css`
  ${cssGradientButton}
  ${cssShadowButton}
`
export const cssCampfireButton = css`
  ${cssLink}
  ${cssShadowButton}
`

export const cssScalehackButton = css`
  ${cssScalehackColor}
  ${cssShadowButton}
`
